import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  policyContainer,
  policyId,
  policyTitle,
  policyText,
} from "../sass/policies.module.scss"

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Guillaume Bouffard | Terms and conditions" />
    <div className={policyContainer}>
      <div className={policyTitle}>Terms and conditions</div>
      <div className={policyText}>
        <strong>Terms of use</strong>
        <div>
          This page (together with the documents referred to on it) sets out the
          terms of use (“Terms”) upon which you may make use of
          www.gbouffard.com (“Site”). Please read these Terms carefully before
          you use the Site. By using the Site, you indicate that you accept
          these Terms and that you agree to abide by them. If you do not agree
          to these Terms, please refrain from using the Site.
        </div>
        <strong>Ownership</strong>
        <div>
          www.gbouffard.com is a website operated by Guillaume Bouffard.
        </div>
        <strong>Accessing the site</strong>
        <div>
          Access to the Site is permitted on a temporary basis and Guillaume
          reserves the right to withdraw or amend the service provided on the
          Site without notice. Guillaume will not be liable if for any reason
          the Site is unavailable at any time or for any period. If at any
          point, you are provided with a user identification code, password or
          any other piece of information as part of a security procedure, you
          must treat such information as confidential, and you must not disclose
          it to any third party. Guillaume has the right to disable any user
          identification code or password, whether chosen by you or allocated by
          him, at any time, if in our opinion you have failed to comply with any
          of the provisions of these Terms. From time to time, Guillaume may
          suspend or restrict access to the Site (or parts of the Site), or
          close the Site indefinitely. You are responsible for ensuring that
          whoever accesses the Site through your internet connection is aware of
          these Terms, and that he/she complies with them.
        </div>
        <strong>Intellectual property rights</strong>
        <div>
          Guillaume is the owner or the licensee of all intellectual property
          rights in the Site, and in the material published on it. Those works
          are protected by copyright laws. All such rights are reserved. You may
          print off one copy, and may download extracts, of any page(s) from the
          Site for your personal reference and you may draw the attention of
          others within your organisation to material posted on the Site. You
          must not modify the paper or digital copies of any materials you have
          printed off or downloaded from the Site in any way, and you must not
          use any illustrations, photographs, video or audio sequences or any
          graphics separately from any accompanying text. Guillaume must always
          be acknowledged as the authors of material on the Site. You must not
          use the materials on the Site (or any part(s) thereof) for commercial
          purposes without obtaining a prior written licence to do so from
          Guillaume. If you print off, copy or download any part of the Site in
          breach of these Terms, your right to use the Site will cease
          immediately and you must return or destroy any copies of the materials
          you have made.
        </div>
        <strong>Site content</strong>
        <div>
          Commentary and other materials posted on the Site are for information
          only and not intended to amount to advice on which you should rely.
          Guillaume therefore disclaims all liability and responsibility arising
          from any reliance placed on such materials by any visitor to the Site,
          or by anyone who may be informed of any of its contents. Guillaume may
          change the content on the Site at any time. Whilst Guillaume takes
          reasonable steps to ensure the smooth functioning of the Site, he does
          not guarantee that the Site, or any content on it, will be free from
          errors or omissions or that content downloaded from the Site will be
          free from viruses.
        </div>
        <strong>Liability</strong>
        <div>
          The material displayed on the Site is provided without any guarantees,
          conditions or warranties as to its accuracy. To the extent permitted
          by law, Guillaume shall not be liable, whether in tort (including for
          negligence or breach of statutory duty), contract, or
          misrepresentation or otherwise for:
          <ul>
            <li>use of or inability to use the Site.</li>
            <li>
              use of or reliance on any content displayed on the Site; – loss of
              profits.
            </li>
            <li>loss of business.</li>
            <li>depletion of goodwill and/or similar losses.</li>
            <li>loss of anticipated savings.</li>
            <li>loss of goods.</li>
            <li>loss of contract.</li>
            <li>loss of use.</li>
            <li>loss of corruption of data or information.</li>
            <li>
              any special, indirect, consequential or pure economic loss, costs,
              damages, charges or expenses.
            </li>
          </ul>
          Nothing in these Terms excludes or limits Guillaume's liability for
          death or personal injury arising from his negligence, nor his
          liability for fraud or fraudulent misrepresentation, nor any other
          liability which cannot be excluded or limited under European law.
          Guillaume will not be liable for any loss or damage caused by a virus,
          distributed denial-of-service attack, or other technologically harmful
          material that may infect your computer equipment, computer programs,
          data or other proprietary material due to your use of the Site or to
          your downloading of any content on it, or on any website linked to it.
          Guillaume assumes no responsibility for the content of websites linked
          on the Site. Such links should not be interpreted as endorsement by
          Guillaume of those linked websites. Guillaume will not be liable for
          any loss or damage that may arise from your use of them.
        </div>
        <strong>Information about you And your visits to the site</strong>
        <div>
          Guillaume processes information about you in accordance with the
          following privacy policy
          http://www.gbouffard.com/terms-and-conditions#privacypolicy By using
          the Site, you consent to such processing and you warrant that all data
          provided by you is accurate.
        </div>
        <strong>Prohibited uses</strong>
        <div>
          You may use the Site only for lawful purposes. In particular, you may
          not use the Site:
          <ul>
            <li>
              in any way that breaches any applicable local, national or
              international law or regulation.
            </li>
            <li>
              in any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect.
            </li>
            <li>
              for the purpose of harming or attempting to harm minors in any
              way.
            </li>
            <li>
              to send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards below.
            </li>
            <li>
              to transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>
            <li>
              to knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </li>
          </ul>
          You also agree:
          <ul>
            <li>
              not to reproduce, duplicate, copy or re-sell any part of the Site
              in contravention of the provisions of these Terms.
            </li>
            <li>
              not to access without authority, interfere with, damage or disrupt
              any part of the Site.
            </li>
          </ul>
        </div>
        <strong>Linking to the site</strong>
        <div>
          You may link to the home page provided you do so in a way that is fair
          and legal and does not damage Guillaume's reputation or take advantage
          of it, but you must not establish a link in such a way as to suggest
          any form of association, approval or endorsement on his part where
          none exists. You must not establish a link to the Site from any
          website that is not owned by you. The Site must not be framed on any
          other site, nor may you create a link to any part of the Site other
          than the home page. Guillaume reserves the right to withdraw linking
          permission without notice. If you wish to make any use of material on
          the Site other than that set out above, please address your request
          using the contact section of the Site.
        </div>
        <strong>Links from the site</strong>
        <div>
          Where the Site contains links to other sites and resources provided by
          third parties, these links are provided for your information only.
          Guillaume has no control over the contents of those sites or
          resources, and accepts no responsibility for them or for any loss or
          damage that may arise from your use of them.
        </div>
        <strong>Suspension and termination</strong>
        <div>
          Guillaume will determine, in his discretion, whether there has been a
          breach of these Terms through your use of the Site. When a breach of
          these Terms has occurred, Guillaume may take such action as he deems
          appropriate.
        </div>
        <strong>Jurisdiction and applicable law</strong>
        <div>
          Your use of the Site and any issues arising from it are governed by
          European law. The European courts will have non-exclusive jurisdiction
          over any claim arising from, or related to, a visit to the Site
          although Guillaume retains the right to bring proceedings against you
          for breach of these conditions in your country of residence or any
          other relevant country.
        </div>
        <strong>Variations</strong>
        <div>
          Guillaume may revise these Terms at any time by amending this page.
          You are expected to check this page from time to time to take notice
          of any changes Guillaume made, as they are binding on you. Some of the
          provisions contained in these Terms may also be superseded by
          provisions or notices published elsewhere on the Site.
        </div>
        <strong>Contact</strong>
        <div>
          If you have any concerns about material which appears on the Site,
          please contact Guillaume via the Contact page of the Site. Thank you
          for visiting this site.
        </div>
      </div>
      <div className={policyTitle}>
        Privacy Policy
        <div className={policyId} id="privacypolicy"></div>
      </div>
      <div className={policyText}>
        <div>
          This policy (together with the terms of use
          http://www.gbouffard.com/terms-and-conditions and any other documents
          referred to in it) sets out the basis on which any personal data
          Guillaume collects from you, or that you provide will be processed.
          Please read this policy carefully before you use the site. By visiting
          the website www.gbouffard.com (“Site”), you indicate that you accept
          this policy and that you agree to abide by it. For the purpose of the
          Data Protection Act 1998 (“Act”), the data controller is Guillaume
          Bouffard and the information he may collect about you includes:
          <ul>
            <li>
              Information that you provide by filling in forms on the Site. This
              includes information you providesuch as name, address, email
              address and/or phone number. Guillaume may also ask you for
              information when you report a problem with the Site.
            </li>
            <li>
              If you contact him, Guillaume may keep a record of that
              correspondence.
            </li>
            <li>
              Although not tracked at the moment but could be in the future,
              details of your visits to the Site including, but not limited to,
              traffic data, location data, weblogs and other communication data,
              and the resources that you access.
            </li>
          </ul>
        </div>
      </div>
      <div className={policyTitle}>
        Cookies Policy
        <div className={policyId} id="cookiespolicy"></div>
      </div>
      <div className={policyText}>
        <div>
          The Site does not use cookies at this point and no information is
          stored from the site itself. Cookies allow to distinguish you from
          other users of the Site. For detailed information on the cookies that
          can be used in the future and the purposes for which Guillaume might
          use them, see the Cookie Policy on this page. If cookies get used on
          the Site in the future, the following policy will be amended.
        </div>
        <strong>Where Guillaume store your personal data</strong>
        <div>
          In the future, the data that Guillaume collects from you may be
          transferred to, and stored at, a location outside the European
          Economic Area (“EEA”). By submitting your personal data, you agree to
          this transfer, storing or processing. Guillaume will take all steps
          reasonably necessary to ensure that your data is treated securely and
          in accordance with this privacy policy. Unfortunately, the
          transmission of information via the internet is not completely secure.
          Although Guillaume will do his best to protect your personal data,
          Guillaume cannot guarantee the security of your data transmitted to
          the Site; any transmission is at your own risk. Once Guillaume has
          received your information, Guillaume will use strict procedures and
          security features to try to prevent unauthorised access.
        </div>
        <strong>Uses made of the information</strong>
        <div>
          In the future Guillaume might use information held about you in the
          following ways:
          <ul>
            <li>
              To ensure that content from the Site is presented in the most
              effective manner for you and for your computer.
            </li>
            <li>
              To provide you with information and updates about my activities,
              products or services that you request from me or which Guillaume
              feels may interest you, where you have consented to be contacted.
            </li>
            <li>
              To allow you to participate in interactive features of his
              service, when you choose to do so.
            </li>
          </ul>
        </div>
        <strong>Disclosure of your information</strong>
        <div>
          In the future Guillaume could disclose your personal information to
          third parties:
          <ul>
            <li>
              In the event that Guillaume's business sells or buys any business
              or assets, in which case Guillaume may disclose your personal data
              to the prospective seller or buyer of such business or assets.
            </li>
            <li>
              If Guillaume's business or substantially all of its assets are
              acquired by a third party, in which case personal data held will
              be one of the transferred assets.
            </li>
            <li>
              If Guillaume is under a duty to disclose or share your personal
              data in order to comply with any legal obligation,or in order to
              enforce or apply the terms of use or to protect the rights,
              property, or safety of his own business. This includes exchanging
              information with other companies and organisations for the
              purposes of fraud protection and credit risk reduction.
            </li>
          </ul>
        </div>
        <strong>Your rights</strong>
        <div>
          You have the right to ask Guillaume not to process your personal data.
          Guillaume will usually inform you (before collecting your data) if he
          intends to use your data or if he intends to disclose your information
          to any third party for such purposes. You can exercise your right to
          prevent such processing by asking him not to. You can also exercise
          the right at any time by contacting him on the contact page. The Site
          may, from time to time, contain links to and from the websites to
          other websites. If you follow a link to any of these websites, please
          note that these websites have their own privacy policies and that
          Guillaume do not accept any responsibility or liability for these
          policies. Please check these policies before you submit any personal
          data to these websites.
        </div>
        <strong>Access to information</strong>
        <div>
          The Act gives you the right to access information held about you. Your
          right of access can be exercised in accordance.
        </div>
        <strong>Changes to our privacy policy</strong>
        <div>
          Please note that this privacy policy may change from time to time.
          Guillaume expects the majority of changes to be minor; however,
          Guillaume will not reduce your rights under this privacy policy
          without your explicit consent. Any changes Guillaume make will be
          posted on this page and, if the changes are significant, Guillaume
          will provide a more prominent notice (including but not limited to an
          email notification of privacy policy changes.)
        </div>
        <strong>Contact</strong>
        <div>
          Questions, comments and requests regarding this privacy policy should
          be addressed via the contact page of gbouffard.com
        </div>
        <strong>Cookie policy</strong>
        <div>
          Guillaume may collect information about your computer, including where
          available your IP address, operating system and browser type, for
          system administration or debugging purposes. This is statistical data
          about the users’ browsing actions and patterns, and does not identify
          any individual. For the same reason, Guillaume may obtain information
          about your general internet usage by using a cookie file which is
          stored on the hard drive of your computer. Although not used at the
          moment, cookies contain information that is transferred to your
          computer’s hard drive. They help to improve the website (“Site”) and
          to deliver a better and more personalised service. They enable
          Guillaume:
          <ul>
            <li>To estimate the audience size and usage pattern.</li>
            <li>
              To store information about your preferences, and so allow us to
              customise the Site according to your individual interests.
            </li>
            <li>To speed up your searches.</li>
            <li>
              To recognise you when you return to the Site. You may refuse to
              accept cookies by activating the setting on your browser which
              allows you to refuse the setting of cookies. However, if you
              select this setting you may be unable to access certain parts of
              the Site. Unless you have adjusted your browser setting so that it
              will refuse cookies, our system will issue cookies when you log on
              to the Site
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsAndConditions
